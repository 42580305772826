.card {
  &.collapsible {
    .header {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: calc(50% - 11px);
        right: 2.25rem;
        width: 22px;
        height: 22px;
        background: transparent url('../../assets/menu-toggle-b.svg') center center no-repeat;
        background-size: 100%;
        opacity: .35;
        transform: rotateZ(90deg);
      }
    }

    &.collapsed {
      .header {
        padding-bottom: 1px !important;
        border-bottom: none;

        &:after {
          transform: rotateZ(-90deg);
        }
      }

      .body {
        display: none;
      }
    }
  }

  .header {
    border-top: none;
    border-right: none;
    border-left: none;
  }

  @media (prefers-color-scheme: dark) {
    &.collapsible {
      .header {
        &:after {
          background-image: url('../../assets/menu-toggle-w.svg');
        }
      }
    }
  }
}
