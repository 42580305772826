//
// Global variables for Bootstrap customization.
// To override any Bootstrap variable safely you can use _variables.demo.scss
//

//
// Color system
//

// Black color
$black:    											#000000 !default;

// White color
$white:    											#ffffff !default;

// Gray colors
$gray-100: 											#F3F6F9 !default;
$gray-200: 											#ECF0F3 !default;
$gray-300: 											#E5EAEE !default;
$gray-400: 											#D6D6E0 !default;
$gray-500: 											#B5B5C3 !default;
$gray-600: 											#80808F !default;
$gray-700: 											#464E5F !default;
$gray-800: 											#1B283F !default;
$gray-900: 											#212121 !default;

// Dark colors
$dark:          									$gray-900 !default;
$dark-75:          									$gray-700 !default; // Custom variable
$dark-50:          									$gray-600 !default; // Custom variable
$dark-25:          									$gray-400 !default; // Custom variable

// Text muted
$text-muted:                  						$gray-500 !default; // Custom variable

// Gray colors list
$grays: (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
	"700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
) !default; // Custom variable

// Bootstrap standard & extended colors
// Dark colors
$dark:          									$dark !default;
$dark-hover:          								#111111 !default; // Custom variable
$dark-light:          							    $gray-400 !default; // Custom variable
$dark-inverse:         								$white !default; // Custom variable

// Primary colors
$primary:       									#3699FF !default;
$primary-hover:    									#187DE4 !default; // Custom variable
$primary-light:    									#E1F0FF !default; // Custom variable
$primary-inverse:  									$white !default;  // Custom variable

// Secondary colors
$secondary:     									$gray-300 !default;
$secondary-hover:  									darken($gray-300, 4%) !default; // Custom variable
$secondary-light:  									$gray-200 !default; // Custom variable
$secondary-inverse:									$dark-75 !default; // Custom variable

// Success colors
$success:       									#1BC5BD !default;
$success-hover:    									#0BB7AF !default; // Custom variable
$success-light:    									#C9F7F5 !default; // Custom variable
$success-inverse:  									$white !default; // Custom variable

// Inco colors
$info:         		 								#8950FC !default;
$info-hover:   		 								#7337EE !default; // Custom variable
$info-light:   		 								#EEE5FF !default; // Custom variable
$info-inverse: 		 								$white !default; // Custom variable

// Warning colors
$warning:       									#FFA800 !default;
$warning-hover:    									#EE9D01 !default; // Custom variable
$warning-light:    									#FFF4DE !default; // Custom variable
$warning-inverse:  									$white !default; // Custom variable

// Danger colors
$danger:        									#F64E60 !default;
$danger-hover:     									#EE2D41 !default; // Custom variable
$danger-light:     									#FFE2E5 !default; // Custom variable
$danger-inverse:   									$white !default; // Custom variable

// Light colors
$light:         									$gray-100 !default;
$light-hover:         								$gray-300 !default; // Custom variable
$light-light:         								$gray-100 !default; // Custom variable
$light-inverse:        								$gray-600 !default; // Custom variable

// White colors
$white:    											$white !default;
$white-hover:    									$gray-100 !default; // Custom variable
$white-light:    									$white !default;  // Custom variable
$white-inverse:    									$dark-75 !default; // Custom variable

// Bootstrap theme colors
$theme-colors: (
	"white":      $white,  // custom color type
	"primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
) !default;

// Extended hover colors for Bootstrap theme colors
$theme-hover-colors: (
	"white":      $white-hover,   // custom color type
	"primary":    $primary-hover,
    "secondary":  $secondary-hover,
    "success":    $success-hover,
    "info":       $info-hover,
    "warning":    $warning-hover,
    "danger":     $danger-hover,
    "light":      $light-hover,
    "dark":       $dark-hover
) !default;  // Custom variable

// Extended inverse colors for Bootstrap theme colors
$theme-inverse-colors: (
	"white":      $white-inverse,
	"primary":    $primary-inverse,
    "secondary":  $secondary-inverse,
    "success":    $success-inverse,
    "info":       $info-inverse,
    "warning":    $warning-inverse,
    "danger":     $danger-inverse,
    "light":      $light-inverse,
    "dark":       $dark-inverse
) !default;  // Custom variable

// Extended outline colors for Bootstrap theme colors
$theme-outline-inverse-colors: (
	"white":      $white,
	"primary":    $primary,
    "secondary":  $dark-75,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $dark-75,
    "dark":       $dark
) !default;  // Custom variable

// Extended light colors for Bootstrap theme colors
$theme-light-colors: (
    "white":      $white-light,
	"primary":    $primary-light,
    "secondary":  $secondary-light,
    "success":    $success-light,
    "info":       $info-light,
    "warning":    $warning-light,
    "danger":     $danger-light,
    "light":      $light-light,
    "dark":       $dark-light
) !default;  // Custom variable

// Extended shadow colors for Bootstrap theme colors
$theme-shadow-colors: (
    "white":      $dark,
	"primary":    $primary,
    "secondary":  $dark,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $dark,
    "dark":       $dark
) !default;  // Custom variable

// Extended theme text colors
$theme-text-colors: (
    "white":      $white,
	"primary":    $primary,
    "secondary":  $dark,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      light,
    "dark":       $dark,
    "dark-75":    $dark-75,
    "dark-50":    $dark-50,
    "dark-25":    $dark-25,
    "muted":      $text-muted
) !default;  // Custom variable

// Social Network Colors Used With Boostrap Buttons(see: https://primarycolors.net/)
$social-colors: (
	facebook: (
		base: #3b5998,
		inverse: #ffffff,
        light: rgba(#3b5998, 0.1),
        hover: darken(#3b5998, 7.5%)
	),
	google: (
		base: #dd4b39,
		inverse: #ffffff,
        light: rgba(#dd4b39, 0.1),
        hover: darken(#dd4b39, 7.5%)
	),
	twitter: (
		base: #1da1f2,
		inverse: #ffffff,
        light: rgba(#1da1f2, 0.1),
        hover: darken(#1da1f2, 7.5%)
	),
	instagram: (
		base: #e1306c,
		inverse: #ffffff,
        light: rgba(#e1306c, 0.1),
        hover: darken(#e1306c, 7.5%)
	),
	youtube: (
		base: #ff0000,
		inverse: #ffffff,
        light: rgba(#ff0000, 0.1),
        hover: darken(#ff0000, 7.5%)
	),
    linkedin: (
        base: #0077b5,
        inverse: #ffffff,
        light: rgba(#0077b5, 0.1),
        hover: darken(#0077b5, 7.5%)
    ),
    skype: (
        base: #00aff0,
        inverse: #ffffff,
        light: rgba(#00aff0, 0.1),
        hover: darken(#00aff0, 7.5%)
    )
) !default; // Custom variable

// Extended custom spacing for Bootstrap
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: (
    0: 0,
    1: ($spacer * .25),  	// 3.5px
    2: ($spacer * .5),   	// 7px;
	3: ($spacer * .75),  	// 10.5px
	4: ($spacer * 1),    	// 14px
	5: ($spacer * 1.25), 	// 17.5px
	6: ($spacer * 1.5),  	// 21px
	7: ($spacer * 1.75), 	// 24.5px
	8: ($spacer * 2),    	// 28px
	9: ($spacer * 2.25), 	// 31.5px
	10: ($spacer * 2.5), 	// 35px
	11: ($spacer * 2.75),	// 38.5px
	12: ($spacer * 3),   	// 42px
	13: ($spacer * 3.25),	// 45.5px
	14: ($spacer * 3.5), 	// 49px
	15: ($spacer * 3.75), 	// 52.5px
	16: ($spacer * 4), 		// 55px
	17: ($spacer * 4.25), 	// 58.5px
	18: ($spacer * 4.5), 	// 62px
	19: ($spacer * 4.75), 	// 65.5px
	20: ($spacer * 5), 		// 69px
	21: ($spacer * 5.25),	// 73.5px
	22: ($spacer * 5.5), 	// 77px
	23: ($spacer * 5.75), 	// 80.5px
	24: ($spacer * 6), 		// 84px
	25: ($spacer * 6.25), 	// 87.5px
	26: ($spacer * 6.5), 	// 91px
	27: ($spacer * 6.75), 	// 94.5px
	38: ($spacer * 7), 		// 99px
	29: ($spacer * 7.25),   // 102.5px
	30: ($spacer * 7.5), 	// 106px
	31: ($spacer * 7.75), 	// 109.5px
	32: ($spacer * 8), 		// 113px
	33: ($spacer * 8.25), 	// 116.5px
	34: ($spacer * 8.5), 	// 120px
	35: ($spacer * 8.75), 	// 123.5px
	36: ($spacer * 9), 		// 127px
	37: ($spacer * 9.25),    // 130.5px
	48: ($spacer * 9.5), 	// 134px
	39: ($spacer * 9.75), 	// 137.5px
    40: ($spacer * 10), 	// 140px
);

// Components
//
// Define common padding and border radius sizes and more.
$line-height-xl:									1.8 !default;
$line-height-lg:              						1.5 !default;
$line-height-sm:              						1.35 !default;

$component-light-style-opacity:                 	0.1 !default; // Custom variable
$component-active-color:                            $white !default;
$component-active-bg:                               $primary !default;

// Box shadow
$box-shadow-xs:                                     0 0.25rem 0.5rem 0 rgba($black, 0.05);
$box-shadow-sm:               						0 .1rem 1rem 0.25rem rgba($black, .05) !default;
$box-shadow:                  						0 .5rem 1.5rem 0.5rem rgba($black, .075) !default;
$box-shadow-lg:               						0 1rem 2rem 1rem rgba($black, .1) !default;

// Border Radiues
$border-radius-sm:            						.28rem !default;
$border-radius:               						.42rem !default;
$border-radius-lg:            						.85rem !default;
$border-radius-xl:                                  1.25rem !default; // Custom variable

// Border settings
$border-color:                                      $gray-200 !default;
$border-width:                                      1px !default;

// Hover background color
$hover-bg:                                          $gray-100 !default; // Custom variable

// Activebackground color
$active-bg:                                         darken($gray-100, 1%) !default; // Custom variable

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded: 									true !default;
$enable-shadows:                              		true !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px // custom breakpoint
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1340px
) !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns:                						12 !default;
$grid-gutter-width:           						25px !default;
$grid-divider-bg:                                   $gray-200 !default; // Custom variable


// Body
// Settings for the `<body>` element.
$body-bg:                   						$white !default;
$body-color:                						$dark-75 !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// Font family
$font-family-base:            						Poppins, Helvetica, "sans-serif" !default;

$font-size-base:              						1rem !default; // Assumes the browser default, typically `13px`
$font-size-lg:               	 					$font-size-base * 1.08 !default;   // 14.04px
$font-size-sm:                						$font-size-base * .925 !default;   // 12.025px
$font-size-xs:                						$font-size-base * .8 !default;     // 10.4px

$h1-font-size:                                      $font-size-base * 2 !default;      // 26px
$h2-font-size:                                      $font-size-base * 1.75 !default;   // 22.75px
$h3-font-size:                                      $font-size-base * 1.5 !default;    // 19.5px
$h4-font-size:                                      $font-size-base * 1.35 !default;   // 17.55px
$h5-font-size:                                      $font-size-base * 1.25 !default;   // 16.25px
$h6-font-size:                                      $font-size-base * 1.175 !default;  // 15.275px

$headings-margin-bottom:                            $spacer / 2 !default;
$headings-font-family:                              null !default;
$headings-font-weight:                              500 !default;
$headings-line-height:                              1.2 !default;
$headings-color:                                    null !default;

$display1-size:                                     5.5rem !default;  // 71.5px
$display2-size:                                     4.5rem !default;  // 58.5px
$display3-size:                                     3.5rem !default;  // 45.5px
$display4-size:                                     2.5rem !default;  // 32.5px
$display5-size:                                     2.25rem !default; // 29.5px

// Font Sizes
$font-sizes: (
    font-size-h1: $h1-font-size,
    font-size-h2: $h2-font-size,
    font-size-h3: $h3-font-size,
    font-size-h4: $h4-font-size,
    font-size-h5: $h5-font-size,
    font-size-h6: $h6-font-size,
    display1: $display1-size,
    display2: $display2-size,
    display3: $display3-size,
    display4: $display4-size,
    display5: $display5-size
) !default;

$display1-weight:                                   300 !default;
$display2-weight:                                   300 !default;
$display3-weight:                                   300 !default;
$display4-weight:                                   300 !default;
$display-line-height:                               $headings-line-height !default;

$lead-font-size:                                    $font-size-base * 1.25 !default;
$lead-font-weight:                                  300 !default;
$small-font-size:                                   80% !default;

// Font Weight
$font-weight-lighter:         						lighter !default;
$font-weight-light:           						300 !default;
$font-weight-normal:          						400 !default;
$font-weight-bold:            						500 !default;
$font-weight-bolder:          						600 !default;
$font-weight-boldest:          						700 !default;

$line-height-base:                                  1.5 !default;


// Transition
$transition:                                  		all 0.15s ease !default; // Custom variable
$transition-link:                                   color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease !default; // Custom variable
$transition-input:                                  color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease !default; // Custom variable


// Links
//
// Style anchor elements.
$link-color:                                        $primary !default;
$link-decoration:                                   none !default;
$link-hover-color:                                  darken($primary, 15%) !default;
$link-hover-decoration:                             underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage:           15% !default;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y:                               .65rem !default;
$input-btn-padding-x:                               1rem !default;
$input-btn-font-family:                             null !default;
$input-btn-font-size:                               $font-size-base !default;
$input-btn-line-height:                             $line-height-base !default;

$input-btn-focus-width:                             .2rem !default;
$input-btn-focus-color:                             rgba($component-active-bg, .25) !default;
$input-btn-focus-box-shadow:                        none !default;

$input-btn-padding-y-sm:                            .55rem !default;
$input-btn-padding-x-sm:                            .75rem !default;
$input-btn-font-size-sm:                            $font-size-sm !default;
$input-btn-line-height-sm:                          $line-height-sm !default;

$input-btn-padding-y-lg:                            0.825rem !default;
$input-btn-padding-x-lg:                            1.42rem !default;
$input-btn-font-size-lg:                            $font-size-lg !default;
$input-btn-line-height-lg:                          $line-height-lg !default;

$input-btn-border-width:                            $border-width !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-disabled-opacity:        						.6 !default;
$btn-transition:              						color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out, box-shadow .3s ease-in-out !default;// Custom variable
$btn-box-shadow:                                    none !default;
$btn-focus-box-shadow:                              none !default;
$btn-active-box-shadow:                             none !default;
$btn-font-weight:             						normal !default;
$btn-secondary-color:								$dark-75 !default;
$btn-secondary-hover-bg-color:						$gray-100 !default;
$btn-icon-size-xs:                                  24px !default; //custom parameter

// Allows for customizing button radius independently from global border radius
$btn-border-radius:                                 $border-radius !default;
$btn-border-radius-lg:                              $border-radius !default;
$btn-border-radius-sm:                              $border-radius !default;

$input-plaintext-color:                 			$dark-75 !default;
$input-placeholder-color:               			$text-muted !default;
$input-color:                           			$dark-75 !default;
$input-border-color:								$gray-300 !default;
$input-border-width:                                $input-btn-border-width !default;
$input-box-shadow:                                  none !default;
$input-focus-border-color:							lighten($primary, 10%);
$input-focus-box-shadow:                            none !default;
$input-height-border:                               $input-border-width * 2 !default;

$input-bg:                                          $white !default;
$input-disabled-bg:                     			$gray-100 !default;
$input-readonly-bg:                     			$white !default; // Custom variable
$input-group-icon-color:                     		$dark-75 !default;
$input-group-addon-color:               			$dark-75 !default;
$input-group-addon-bg:                  			$gray-100 !default;

$input-border-radius:                   			$border-radius !default;
$input-border-radius-lg:                			$border-radius !default;
$input-border-radius-sm:                			$border-radius-sm !default;

$input-solid-bg:                                    $gray-100; // Custom variable
$input-solid-bg-focus:                              $gray-200; // Custom variable
$input-solid-placeholder-color:                     $input-placeholder-color;  // Custom variable
$input-solid-color:                              	$dark-75; // Custom variable

// Forms
$form-group-margin-bottom:                          1.75rem !default;
$form-label-font-size:                              1rem !default; // Custom variable
$form-label-font-weight:                            400 !default; // Custom variable
$form-feedback-font-size:                           0.9rem !default; // Custom variable
$form-feedback-font-weight:                         400 !default; // Custom variable
$form-text-font-size:                               0.9rem !default; // Custom variable
$form-text-font-weight:                             400 !default; // Custom variable
$form-validation-input-shadow:                      false;  // Custom variable

// Table
$table-bg:                      					transparent !default;
$table-accent-bg:               					$gray-200 !default;
$table-hover-bg:                					$gray-300 !default;
$table-active-bg:               					$table-hover-bg !default;

$table-border-color:            					$gray-200 !default;
$table-head-bg:                 					$gray-100 !default;
$table-head-color:              					$dark-75 !default;
$table-head-font-size:             					1rem !default; // Custom variable
$table-head-font-weight:           					600 !default; // Custom variable

$table-dark-color:            						$white !default;
$table-dark-bg:               						$dark !default;
$table-dark-accent-bg:        						rgba($white, .05) !default;
$table-dark-hover-color:      						$table-dark-color !default;
$table-dark-hover-bg:        						rgba($white, .075) !default;
$table-dark-border-color:     						lighten($table-dark-bg, 7.5%) !default;

$table-head-bg:                                     $gray-100 !default; // Custom variable
$table-compact-head-font-size:                      0.9rem !default; // Custom variable
$table-compact-head-color:                          $text-muted !default; // Custom variable
$table-compact-head-text-transform:                 uppercase !default; // Custom variable
$table-compact-head-font-weight:                    600 !default; // Custom variable
$table-compact-head-font-weight:                    600 !default; // Custom variable
$table-compact-head-letter-spacing:                 0.1rem !default; // Custom variable

// Card
$card-box-shadow: 									0px 0px 30px 0px rgba(82,63,105,0.05) !default; // card custom shadow
$card-border-color:                 				$gray-200 !default;
$card-cap-bg:                       				$white !default;
$card-spacer-y:                     				2rem !default;
$card-spacer-x:                    					2.25rem !default;
$card-border-radius:                				$border-radius !default;

$card-header-spacer-y:                              0.5rem !default; // Custom variable
$card-header-height: 								70px !default; // Custom variable
$card-sticky-header-bg:   						    $white !default; // Custom variable
$card-sticky-header-height: 						70px !default; // Custom variable
$card-sticky-zindex:                                101  !default; // Custom variable
$card-sticky-shadow:                                0px 1px 15px 1px rgba(69, 65, 78, 0.1) !default; // Custom variable

// List group
$list-group-border-color:           				$gray-200 !default;
$list-group-hover-bg:               				$gray-200 !default;


// Modals

// Padding applied to the modal body
$modal-inner-padding:              					1.75rem !default;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between:       				.5rem !default;

$modal-dialog-margin:               				.5rem !default;
$modal-dialog-margin-y-sm-up:       				1.75rem !default;

$modal-content-color:               				null !default;
$modal-content-bg:                  				$white !default;
$modal-content-border-color:        				rgba($black, .2) !default;
$modal-content-border-width:        				0 !default;
$modal-content-border-radius:       				$border-radius !default;
$modal-content-inner-border-radius: 				$border-radius !default;

$modal-content-box-shadow:       				    0 .25rem .5rem rgba($black, .05) !default;
$modal-content-box-shadow-xs:       				0 .25rem .5rem rgba($black, .1) !default;
$modal-content-box-shadow-sm-up:   					0 .5rem 1rem rgba($black, .1) !default;

$zindex-modal:                                      1050 !default;
$modal-backdrop-bg:                		 			$black !default;
$modal-backdrop-opacity:            				.2 !default;
$modal-header-border-color:         				$border-color !default;
$modal-footer-border-color:         			 	$border-color !default;
$modal-header-border-width:         				1px !default;
$modal-footer-border-width:         				1px !default;
$modal-header-padding-y:            				1.5rem !default;
$modal-header-padding-x:            				1.75rem !default;
$modal-header-padding:              				$modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl:                         		 			1140px !default;
$modal-lg:                          				800px !default;
$modal-md:                          				500px !default;
$modal-sm:                          				300px !default;


// Pagination
$pagination-color:                  				$primary !default;
$pagination-border-color:           				$gray-300 !default;
$pagination-hover-bg:              	 				$gray-200 !default;
$pagination-hover-border-color:     				$gray-300 !default;
$pagination-disabled-border-color:  				$gray-200 !default;


// Dropdowns
// Dropdown menu container and contents.
$dropdown-box-shadow:								0px 0px 50px 0px rgba(82,63,105, 0.15) !default;
$zindex-dropdown:                   				98 !default;
$dropdown-border-radius:                            $border-radius !default;
$dropdown-border-width:                             0 !default;
$dropdown-item-padding-y:                           .75rem !default;
$dropdown-item-padding-x:                           1.25rem !default;
$dropdown-divider-bg:                               $gray-200 !default;
$dropdown-menu-widths: (
	sm: 175px,
	md: 250px,
	lg: 350px,
	xl: 400px,
	xxl: 600px
); // Custom variable


// Popovers
$popover-font-size:                 				0.9rem !default;
$popover-max-width:                 				276px !default;
$popover-border-width:              				1px !default;
$popover-border-color:              				#ffffff !default;
$popover-border-radius:             				$border-radius !default;
$popover-box-shadow:                				0px 0px 20px 0px rgba(0,0,0,0.15) !default;
$popover-header-bg:                 				#ffffff !default;
$popover-header-color:              				$dark !default;
$popover-header-font-weight:           				500 !default;  // Custom variable
$popover-header-font-size:             				1rem !default;  // Custom variable
$popover-header-padding-y:          				1rem !default;
$popover-header-padding-x:          				1.25rem !default;
$popover-header-border-color:                       $gray-200 !default;  // Custom variable
$popover-body-color:                				$body-color !default;


// Tooltips
$tooltip-font-size:                 				0.9rem !default;
$tooltip-max-width:                 				200px !default;
$tooltip-color:                     				$dark-75 !default;
$tooltip-bg:                        				#ffffff !default;
$tooltip-border-radius:             				3px !default;
$tooltip-opacity:                   				1 !default;
$tooltip-box-shadow:                				$popover-box-shadow !default; // Custom variable
$tooltip-padding-y:                 				.75rem !default;
$tooltip-padding-x:                 				1rem !default;
$tooltip-margin:                    				0 !default;

// Custom Toasts
$toast-zindex:										$zindex-dropdown + 100 !default;
$toast-spacing:										2rem  !default;
$toast-width: 										350px !default;
$toast-box-shadow:									$dropdown-box-shadow !default;
$toast-header-color: 								$dark-75 !default;
$toast-header-background-color: 					#fff !default;
$toast-header-border-color:							$gray-200 !default;


// Progress bars
$progress-height:                                   1rem !default;
$progress-height-xs:                                0.5rem !default; // Custom variable
$progress-height-sm:                                0.75rem !default; // Custom variable
$progress-height-lg:                                1.5rem !default; // Custom variable
$progress-bg:                                       $gray-200 !default;
$progress-border-radius:                            $border-radius !default;
$progress-box-shadow:                               none !default;
$progress-bar-color:                                $white !default;
$progress-bar-bg:                                   $primary !default;
$progress-bar-animation-timing:                     1s linear infinite !default;
$progress-bar-transition:                           width .6s ease !default;

// Badges
$badge-padding-y:                   				.5em !default;
$badge-padding-x:                   				.75em !default;
$badge-font-size:                   				85% !default;


// Code
$code-bg:                                           $gray-100 !default; // Custom variable
$code-padding:                                      0.15rem 0.5rem !default; // Custom variable


// Modals
// Padding applied to the modal body
$modal-inner-padding:                               1rem !default;
$modal-dialog-margin:                               .5rem !default;
$modal-dialog-margin-y-sm-up:                       1.75rem !default;
$modal-title-color:                                 $dark !default; // Custom variable
$modal-title-font-weight:                           500 !default; // Custom variable
$modal-title-font-size:                             1.3rem !default; // Custom variable
$modal-title-small-color:                           $text-muted !default; // Custom variable
$modal-title-small-font-weight:                     400 !default; // Custom variable
$modal-title-small-font-size:                       0.9rem !default; // Custom variable
$modal-title-close-font-size:                       1rem !default; // Custom variable
$modal-title-close-color:                           $text-muted !default; // Custom variable
$modal-title-close-hover-color:                     $primary !default; // Custom variable

$modal-content-color:                               null !default;
$modal-content-bg:                                  $white !default;
$modal-content-border-color:                        rgba($black, .2) !default;
$modal-content-border-radius:                       $border-radius-lg !default;
$modal-content-box-shadow-xs:                       0 .25rem .5rem rgba($black, .5) !default;
$modal-content-box-shadow-sm-up:                    0 .5rem 1rem rgba($black, .5) !default;

$modal-backdrop-bg:                                 $black !default;
$modal-backdrop-opacity:                            .5 !default;
$modal-header-border-color:                         $border-color !default;
$modal-footer-border-color:                         $modal-header-border-color !default;

$modal-xl:                                          1140px !default;
$modal-lg:                                          800px !default;
$modal-md:                                          500px !default;
$modal-sm:                                          300px !default;


// Pagination
$pagination-padding-y:                              .5rem !default;
$pagination-padding-x:                              .75rem !default;
$pagination-padding-y-sm:                           .25rem !default;
$pagination-padding-x-sm:                           .5rem !default;
$pagination-padding-y-lg:                           .75rem !default;
$pagination-padding-x-lg:                           1.5rem !default;
$pagination-line-height:                            1.25 !default;

$pagination-color:                                  $link-color !default;
$pagination-bg:                                     $white !default;
$pagination-border-color:                           $gray-300 !default;

$pagination-focus-box-shadow:                       none !default;
$pagination-focus-outline:                          0 !default;

$pagination-hover-color:                            $link-hover-color !default;
$pagination-hover-bg:                               $gray-200 !default;
$pagination-hover-border-color:                     $gray-300 !default;

$pagination-disabled-color:                         $gray-600 !default;
$pagination-disabled-bg:                            $white !default;
$pagination-disabled-border-color:                  $gray-300 !default;

// Alerts
//
// Define alert colors, border radius, and padding.
$alert-box-shadow:                					$card-box-shadow !default; // Custom variable
