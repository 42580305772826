@import "./theme/components/variables.bootstrap";

$negative: #F64E60;
$positive: #53A200;

$dark-100: #000;
$dark-66: #151515;
$dark-33: #1E1E1E;
$dark-16: #282828;

$light-100: #fff;
$light-66: #E1E1E1;
$light-33: #9D9D9D;
