//
// Quill
//

// Base
.ql-toolbar.ql-snow {
    border: 1px solid $border-color;
    @include border-top-radius($border-radius);

    button {
        &.ql-active,
        &:focus,
        &:hover {
            .ql-stroke {
                stroke: $primary;
            }
        }
    }

    .ql-picker-label {
        &.ql-active,
        &:hover {
            color: $primary;
        }
    }

    .ql-picker-item {
        &.ql-selected,
        &.ql-active,
        &:hover {
            color: $primary;
        }
    }
}

.ql-editor {
    color: $input-color;

    &.ql-blank {
        &:before {
            left: auto !important;
            right: auto !important;
            color: $text-muted !important;
            font-style: normal !important;
        }
    }
}

.ql-container.ql-snow {
    border: 1px solid $gray-200;
    border-top: 0;
    @include border-bottom-radius($border-radius);
}

.ql-snow {
    .ql-picker {
        &.ql-expanded {
            outline: none !important;
            border-color: transparent !important;

            .ql-picker-label {
                border-color: transparent !important;
                color:  $primary;
                outline: none !important;

                &.ql-active,
                &:hover {
                    color: $primary;
                }
            }

            .ql-picker-options {
                border: 0;
                padding: 0.5rem 1rem;
                box-shadow: $dropdown-box-shadow;
                @include border-radius($border-radius);
            }
        }
    }

    .ql-tooltip {
        border: 0;
        padding: 0.5rem 1rem;
        box-shadow: $dropdown-box-shadow;
        @include border-radius($border-radius);

        input[type=text] {
            @include input-reset();
            border: 1px solid $border-color;
            color: $input-plaintext-color;
            outline: none !important;
            @include border-radius($border-radius);

            &:active,
            &:focus {
                border-color: $input-focus-border-color !important;
            }
        }

        .ql-preview {
            color: $dark-50;
        }

        .ql-action {
            transition: color 0.3s ease;
            color: $dark-50;

            &:hover {
                transition: color 0.3s ease;
                color: $primary;
            }
        }

        // Tooltip adjustment in modal
        .modal & {
            &.ql-editing {
                left: 20px !important;
            }
        }
    }

    .ql-editor pre.ql-syntax {
        background-color: $gray-900;
        color: $text-muted;
        overflow: visible;
        @include border-radius($border-radius);
    }
}
