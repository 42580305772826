@import "../../styles/variables";

.valueTable {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;

  &.sortable {
    .header {
      & > div {
        padding: 0;
      }
    }
  }

  .header {
    position: sticky;
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    border-bottom: 1px solid $gray-300;
    background-color: rgba(255, 255, 255, .91);

    & > div {
      padding: 1.1rem 0.75rem;
    }
  }

  .content {
    & > div {
      margin: 0;
      border-bottom: 1px solid $gray-300;

      & > div {
        padding: 1.1rem 0.75rem;
      }
    }
  }

  .sort {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 1.1rem 0.75rem;
    border: 0;
    text-align: left;
    background: transparent;
    transition: background-color linear 75ms;
    cursor: pointer;

    &:hover {
      background-color: $gray-100;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      width: 16px;
      background: transparent url('../../assets/sort-b.svg') left center no-repeat;
      background-size: contain;
      opacity: .2;
      transition: opacity linear 150ms, transform linear 150ms;
    }

    &.desc {
      &:before {
        top: 2px;
        transform: rotateZ(-180deg);
      }
    }

    &.active {
      &:before {
        opacity: .75;
      }
    }
  }

  @media (prefers-color-scheme: dark) {
    .header {
      border-color: $dark-100;
      background-color: unset;
    }

    .content {
      & > div {
        border-color: $dark-100;
      }
    }

    .sort {
      &:hover {
        background-color: $dark-66;
      }

      &:before {
        background: transparent url('../../assets/sort-w.svg') left center no-repeat;
      }
    }
  }
}
