.uploadWrapper {
        display:flex;
        width:100%;
        justify-content: space-evenly;
        align-items: stretch;
        div{
            flex-direction: row;
            box-sizing: content-box;
        }       
}
.error{
    margin-bottom: 25px;
}

.upload{
    border: 1px solid #3f3d3d;
    border-radius: 5%;
    input{fill: #000000;}
    text-align: center;
}

