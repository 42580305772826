@import "variables";

*::-webkit-scrollbar {
  width: auto;
  background-color: $gray-100;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: $gray-400;
  border: 3px solid $gray-100;
}
