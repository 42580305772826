.root {
  width: 100%;
}

.container {
  margin: 0 25px;
  width: 100%;
}

.logo {
  background: transparent url('../../assets/logo-w.png') 19px center no-repeat;
  background-size: 96px;
  display: block;
  width: 130px;
  height: 64px;
  font-size: 0;
}

.profile {
  .icon {
    text-transform: uppercase;
  }
}
