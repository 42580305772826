@import "./../../../../styles/variables";

.balance {
  display: flex;
  min-height: 289px;

  .select {
    flex: 5.4;
    display: flex;
    flex-direction: column;

    .header {
      flex: 1;
      height: auto;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      color: $gray-900;
      font-size: 16px;
      font-weight: 500;
    }

    .items {
      flex: 10;
      overflow-y: scroll;
      height: auto;
      padding: 0;
      margin: 0;
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      list-style-type: none;

      &.empty {
        background: $gray-100;
      }
    }

    .item {
      position: relative;
      font-size: 10px;
      transition: background-color linear 75ms;

      &:hover {
        background-color: $gray-100;
      }

      &.active {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 4px;
          background: $primary;
        }
      }

      button {
        width: 100%;
        padding: 1em;
        margin: 0;
        border: 0;
        background: transparent none;
        color: $gray-600;
      }

      .details {
        padding: 0;

        .in,
        .out {
          margin: 0;

          & > span {
            padding: 0;

            &:first-child,
            &:last-child {
              text-align: right;
            }
          }
        }

        .in {

        }

        .out {

        }
      }

      .name {
        padding: 0;
        color: $gray-900;
        font-weight: 500;
        font-size: 13px;
      }
    }
  }

  .controls {
    flex: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 1em 0 3em 0;
    padding: 0;

    button {
      height: 35px;
      width: 30px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 20px;

      &:disabled {
        opacity: .45;
        cursor: default;
      }

      &.first {
        background-image: url('./../../../../assets/prev-first-g.svg');
      }

      &.last {
        background-image: url('./../../../../assets/next-last-g.svg');
      }

      &.prev {
        background-image: url('./../../../../assets/prev-g.svg');
      }

      &.next {
        background-image: url('./../../../../assets/next-g.svg');
      }
    }
  }

  @media (prefers-color-scheme: dark) {
    .select {
      .item {
        &:hover {
          background-color: rgba(0, 0, 0, 0.5);
        }

        button {
          color: $light-66;
        }

        .name {
          color: $light-33;
        }
      }

      .items {
        &.empty {
          background-color: $dark-66;
        }
      }
    }

    .controls {
      button {
        background-color: $dark-66;
        border-color: $dark-100;

        &:disabled {
          background-color: $dark-66;
          border-color: $dark-100;
          opacity: .35;
        }

        &:hover {
          background-color: $dark-100 !important;
          border-color: $dark-100 !important;
        }
      }
    }
  }
}
