@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.loadingButton {

  &:disabled {
    cursor: default !important;
    opacity: .4 !important;
    filter: grayscale(60%);
  }

  .inner {
    display: inline-block;
    position: relative;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow 0.15s ease, padding-left .15s ease, opacity .15s ease !important;
  }

  &.loads {
    background-color: #8B8B8B !important;
    border-color: #8B8B8B !important;
    cursor: default !important;

    .inner {
      padding-left: 30px;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border: 2px solid white;
        border-radius: 10px;
        opacity: 0;
        animation: appear .25s forwards;
      }

      &:after {
        content: '';
        position: absolute;
        width: 4px;
        height: 12px;
        top: 50%;
        left: 8px;
        transform: rotate(0deg);
        background-color: #8B8B8B;
        transform-origin: top center;
        opacity: 0;
        animation: spin 1.5s linear infinite, appear .25s forwards;
      }
    }
  }
}
