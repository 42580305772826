@import './variables';

.positive {
  color: $positive;
}

.negative {
  color: $negative;
}

.light{
  color: $light-33;
}
