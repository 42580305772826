@import './../../../../styles/variables';
@import './../../../../styles/text';

.ordersSummary {
  .table {
    font-weight: bold;

    thead {
      background: #F3F6F9;
      color: #B5B5C3;
      text-transform: uppercase;

      @media (prefers-color-scheme: dark) {
        background: unset;
        color: $light-66;
      }
    }

    th {
      border-bottom: 0;
      white-space: nowrap;
      font-size: 0.9em;
    }

    tbody {
      tr:first-child {
        td {
          border-top: 0;
        }
      }
    }

    td {
      white-space: nowrap;
      font-size: 1.1em;

      span {
        display: block;
        font-weight: normal;
        color: #B5B5C3;
        font-size: 0.9em;
      }
    }

    .productImg {
      span {
        text-align: center;
        line-height: 50px;
        font-size: 1.3em;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

