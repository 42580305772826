@import "../../../../styles/variables";

.product {
  .box {
    display: flex;
    flex-direction: column;

    &.em {
      .boxContent {
        background-color: #f9f4ee !important;
      }
    }

    &.shy {
      .boxContent {
        background-color: #F9FAFC !important;
      }
    }
  }

  .noBalance {
    display: flex;
    align-items: center;
    text-align: center;

    & > * {
      width: 100%;
    }
  }

  .transfer {
    button {
      text-transform: uppercase;
      color: $gray-600;
    }
  }

  @media (prefers-color-scheme: dark) {
    .box {
      &.em {
        .boxContent {
          background-color: #232219 !important;
        }
      }
    }
  }
}
