.transactionPreview {
  table.table {
    font-size: 1.25rem;

    th {
      font-size: 1.25rem;
      white-space: nowrap;
    }

    td {
      white-space: nowrap;
    }
  }
}
