.logo {
    width: 275px;
    background: #000;
}

.form {
    width: 100%;
    max-width: 275px;

    &.failed {
        label {
            color: red !important;
        }
    }
}
